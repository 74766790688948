import { createRouter, createWebHistory } from "vue-router";
import AuthLayout from '@/Layout/AuthLayout';
//import DeleteAccountLayout from '@/Layout/DeleteAccountLayout';
import DashboardLayout from '@/Layout/DashboardLayout';
import RegistrationLayout from '@/Layout/RegistrationLayout'
import MemberDashboardLayout from "@/Layout/MemberDashboardLayout";
import ClubDashboardLayout from "@/Layout/ClubDashboardLayout";
//import {encryptStorage} from "@/lib/encrypted-storage";
//import axios from "axios";
import HelpLayout from "@/Layout/HelpLayout";
import { decodeKeycloakToken, getRoles, login } from "@/keycloakAuth";
import CryptoJS from 'crypto-js';

const routes = [

    {
        path: "/",
        name: "Home",
        component: function () {
            const host = window.location.host.toLowerCase();
            if (host.includes("afp") || host.includes("pre-afp")) {
                return import("../views/LandingPage/Afplandingpage/AFPLandingPage.vue");
            } else {
                return import("../views/LandingPage/LandingPageFront.vue");
            }
        },
        meta: {
            breadcrumb: "Home",
        },
    },

    {
        name: 'alterLogin',
        path: '/alter-login',
        component: () =>
            import( /* webpackChunkName: "login" */ '../authpages/Login.vue')
    },
    {
        name: 'helpandsupport',
        path: '/help-and-support',
        component: () =>
            import( /* webpackChunkName: "login" */ '../views/LandingPage/HelpAndSupport.vue')
    },
    {
        name: 'usermerge',
        path: '/member_account_merge/:member_id/:currentMemId',
        component: () =>
            import('../views/Registration/UserMergeView.vue'),
    },
    {
        path: '/',
        component: RegistrationLayout,
        children: [{
            path: '/memberregistrations',
            name: 'memberregistrations',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/MemberRegistraion/MemberRegistrionFront.vue'),
            meta: {
                breadcrumb: [{
                    'name': "MemberRegistration",
                    'link': null,
                }],
            },
        },
        {
            path: '/privacy_policy',
            name: 'privacypolicy',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/LandingPage/PrivacyPolicy.vue'),
        },
        {
            path: '/terms_and_conditions',
            name: 'termsandconditions',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/LandingPage/TermsAndConditions.vue'),
        },
        {
            path: '/download-report/:type/:uuid',
            name: 'downloadreport',
            component: () =>
                import( /* webpackChunkName: "DownloadReport" */ '../views/DownloadReport'),
        },
        ]
    },
    {
        path: '/',
        // redirect: 'login',
        component: DashboardLayout,
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Dashboard/Admin/AdminDashboard.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership Hub",
                    'link': null,
                }],
                can: 'dashboard',
            },
        },
        {
            path: '/admin-dashboard',
            name: 'admin-dashboard',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Dashboard/Admin/AdminDashboard.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership Hub",
                    'link': null,
                }],
                can: 'dashboard',
            },
        },
        {
            path: '/registrations/player',
            name: 'playerlist',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/PlayerList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Players Registrations",
                    'link': null,
                }],
                can: 'player',
            },
        },
        {
            path: '/registrations/guest',
            name: 'guestlist',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/GuestList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Guest Registrations",
                    'link': null,
                }],
                can: 'guest',
            },
        },
        {
            path: '/registrations/coach',
            name: 'coachlist',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/CoachList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Coach Registrations",
                    'link': null,
                }],
                can: 'coach',
            },
        },
        {
            path: '/registrations/team',
            name: 'teamlist',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/TeamList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Team Registrations",
                    'link': null,
                }],
                can: 'team',
            },
        },
        /*{
            path: '/admin/faq_sections',
            name: 'FaqSections',
            component: () => import(/!* webpackChunkName: "DashboardLayout" *!/ '../views/Faq/FaqSections.vue'),
                import ( /* webpackChunkName: "DashboardLayout" '../views/Faq/FaqSections.vue'),
            meta: {
                breadcrumb: [{
                    'name': "FAQ Sections",
                    'link': null,
                }],
                can: 'faq_sections',
            },
        },*/
        {
            path: '/membership-pricing',
            name: 'membership-pricing',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/MembershipPricing/MembershipPricing.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership Pricing",
                    'link': null,
                }],
                can: "membership_pricing",
            },
        },
        {
            path: '/admin/playerTransfer/request',
            name: 'admin-player-transfer-request',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/PlayerTransferRequestView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Player Transfer Request List",
                    'link': null,
                }],
                can: "player_transfer_request"
            },
        },
        {
            path: '/admin/playerTransfer/history',
            name: 'admin-player-transfer-history',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/PlayerTransferHistoryView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Player Transfer History List",
                    'link': null,
                }],
                can: "player_transfer_history"
            },
        },
        {
            path: '/admin/playerTransfer/enable',
            name: 'admin-player-transfer-enable',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/PlayerTransferEnableView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Player Transfer Enable",
                    'link': null,
                }],
                can: "player_transfer_enable"
            },
        },
        {
            path: '/admin/faq',
            name: 'faq',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Faq/FaqManagementList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "FAQs",
                    'link': null,
                }],
                can: 'faq',
            },
        },
        {
            path: '/admin-user',
            name: 'admin-user',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Security/AdminUserList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Manage Users",
                    'link': null,
                }],
                can: "manage_users",
            },
        },
        {
            path: '/admin-role',
            name: 'admin-role',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Security/AdminUserRole.vue'),
            meta: {
                breadcrumb: [{
                    'name': "User Roles",
                    'link': null,
                }],
                can: "manage_roles",
            },
        },
        {
            path: '/other-pricing',
            name: 'other-pricing',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/MembershipPricing/OtherPricing.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Other Pricing",
                    'link': null,
                }],
                can: "other_pricing",
            },
        },
        {
            path: '/admin/member_type',
            name: 'member-type',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/MemberType.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Type",
                    'link': null,
                }],
                can: "member_type",
            },
        },
        {
            path: '/admin/content-library',
            name: 'content-library',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Resource.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Content Library",
                    'link': null,
                }],
                can: 'resource',
            },
        },

        {
            path: '/team-report',
            name: 'team-report',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/teamReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Team Registration Reports",
                    'link': null,
                }],
                can: "team_reports",
            },
        },
        {
            path: '/donation-report',
            name: 'donation-report',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/DonationReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Donation Reports",
                    'link': null,
                }],
                can: "donations",
            },
        },
        {
            path: '/financials-report',
            name: 'financials-report',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/FinancialsReports.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Financial Reports",
                    'link': null,
                }],
                can: "financials_report",
            },
        },
        {
            path: '/summary/summary-report',
            name: 'summary-report',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/SummaryReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Summary Reports",
                    'link': null,
                }],
                can: "summery_reports",
            },
        },
        {
            path: '/summary/disciplines',
            name: 'summary-disciplines',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/SummaryDisciplines.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Summary disciplines",
                    'link': null,
                }],
                can: "summery_disciplines",
            },
        },
        {
            path: '/summary/memberships',
            name: 'summary-memberships',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/SummaryMemberships.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Summary memberships",
                    'link': null,
                }],
                can: "summery_memberships",
            },
        },
        {
            path: '/summary/financials',
            name: 'summary-financials',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/SummaryFinancials.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Summary financials",
                    'link': null,
                }],
                can: "summery_financials",
            },
        },
        {
            path: '/ncsa/players-report',
            name: 'ncsa-players-report',
            component: () =>
                import( /* webpackChunkName: "NCSAPlayersReport" */ '../views/FreeAgent/NCSAPlayersReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "NCSA Players Report",
                    'link': null,
                }],
                can: "ncsa_player_reports",
            },
        },
        {
            path: '/ncsa/teams-report',
            name: 'ncsa-teams-report',
            component: () =>
                import( /* webpackChunkName: "NCSATeamsReport" */ '../views/FreeAgent/NCSATeamsReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Recruiting Team Registration Reports",
                    'link': null,
                }],
                can: "ncsa_team_reports",
            },
        },
        {
            path: '/member/renewal',
            name: 'member-renewal',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/MemberRenewal/Renewal.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Renewal",
                    'link': null,
                }],
                can: "renew"
            },
        },
        {
            path: '/coupons',
            name: 'Coupons',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Coupons/Coupons.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Coupons",
                    'link': null,
                }],
                can: 'coupons',
            },
        },
        {
            path: '/admin/membership',
            name: 'membership',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Membership.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership",
                    'link': null,
                }],
                can: "membership",
            },
        },
        {
            path: '/player-free-agent',
            name: 'player-free-agent',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/FreeAgent.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Free Agent Player List",
                    'link': null,
                }],
                can: "free_agent_player",
            },
        },
        {
            path: '/coach-free-agent',
            name: 'coach-free-agent',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/StaffFreeAgent'),
            meta: {
                breadcrumb: [{
                    'name': "Free Agent Coach List",
                    'link': null,
                }],
                can: "free_agent_coach",
            },
        },
        {
            path: '/profile-view',
            name: 'adminProfileView',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/Registration/ProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Admin Profile View",
                    'link': null,
                }],
            }
        },
        {
            path: '/member-dashboard-cms',
            name: 'memberdashboard-cms',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CMS/CmsDashboard/MemberDashboard.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Dashboard",
                    'link': null,
                }],
                can: 'member_dashboard',
            }
        },
        {
            path: '/member-landing-page',
            name: 'memberlandingpage-cms',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CMS/CmsLandingPage/MemberLandingPage.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Landing Page",
                    'link': null,
                }],
                can: 'landing_page',
            }
        },
        {
            path: '/season',
            name: 'season',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CMS/CmsDashboard/Season.vue'),
            meta: {
                isAuthPage: false,
                requiresAuth: false,
                breadcrumb: [{
                    'name': "Season",
                    'link': null,
                }],
                can: 'season',
            }

        },
        {
            path: '/summary/memberships',
            name: 'summary-memberships',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/FreeAgent/SummaryMemberships.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Memberships Summary",
                    'link': null,
                }],
                can: "summery_memberships",
            }
        },
        {
            path: '/summary/financials',
            name: 'summary-financials',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/FreeAgent/SummaryFinancials.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Financials Summary",
                    'link': null,
                }],
                can: "summery_financials",
            }
        },
        {
            path: '/template-type',
            name: 'templatetype',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/TemplateType.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Template Types",
                    'link': null,
                }],
                can: 'template_type'
            }
        },
        {
            path: '/email-template',
            name: 'emailtemplate',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/EmailTemplate.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Templates",
                    'link': null,
                }],
                can: 'email_template'
            },
        },
        {
            path: '/create-campaign',
            name: 'CreateCampaign',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/CreateCampaign.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Create Campaign",
                    'link': null,
                }],
                can: 'create_campaign'
            },
        },
        {
            path: '/campaign-history-reports',
            name: 'CampaignHistoryReports',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/CampaignHistoryReports.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Campaign History Reports",
                    'link': null,

                }],
                can: 'campaign_history'
            },
        },
        {
            path: "copy-campaign/:id",
            name: "CopyCampaign",
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/CreateCampaign.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Campaign Manager / Campaign History Reports",
                    'link': null,
                }],
                name: 'Copy',
                can: 'campaign_history',
            },
        },
        {
            path: "edit-campaign/:id",
            name: "EditCampaign",
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/CreateCampaign.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Campaign Manager / Campaign History Reports",
                    'link': null,
                }],
                can: 'campaign_history',
                name: 'Edit'
            },
        },
        {
            path: "campaign-history-view/:id",
            name: "campaign-history-view",
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/CampaignManagement/CampaignHistoryView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Campaign Manager / Campaign History Reports",
                    'link': null,
                }],
                can: 'campaign_history',
                name: 'Edit'
            },
        },
        {
            path: '/member-reports',
            name: 'member-report',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/FreeAgent/memberReport.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Registration Reports",
                    'link': null,
                }],
                can: "member_reports",
            },
        },
        {
            path: 'profile/:id',
            name: 'profile',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Profile",
                    'link': null,
                }],
                can: "profileView",
            },
        },
        {
            path: 'club_profile/:id',
            name: 'clubprofile',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ClubProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Profile",
                    'link': null,
                }],
                can: "clubProfileView",
            },
        },
        {
            path: '/registrations/family',
            name: 'familylist',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/FamilyList.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Family Registrations",
                    'link': null,
                }],
                can: 'family',
            },
        },
        {
            path: 'family-profile/:id',
            name: 'adminFamilyProfileView',
            component: () =>
                import( /* webpackChunkName: "login" */ '../views/Registration/FamilyProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Admin Profile View",
                    'link': null,
                }],
                can: 'familyProfileView',
            }
        },
        {
            path: '/instalment',
            name: 'instalment',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ProfileComponents/Instalment.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Installment History",
                    'link': null,
                }],
            },
            can: 'instalment',
        },
        {
            path: 'waiver',
            name: 'waiver',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/CMS/Waiver/Waiver.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Waivers",
                    'link': null,
                }],
                can: 'waiver',
            },
        },
        {
            path: '/registrations-settings',
            name: 'registrations-settings',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Security/registrationSetting.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Registration Settings",
                    'link': null,
                }],
                can: "registrations_settings",
            },
        }
        ]
    },
    {
        path: '/',
        component: HelpLayout,
        children: [{
            path: '/help',
            name: 'help',
            component: () =>
                import('../views/Help/Help.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Help Center",
                    'link': null,
                }],
                can: 'dashboard',
            }
        },
        {
            path: '/help/club-admin',
            name: 'clubadmin',
            component: () =>
                import('../views/Help/ClubAdmin.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Help Center",
                    'link': '/help',
                },
                {
                    'name': "Club Admin",
                    'link': null,
                }
                ],
                can: 'dashboard',
            },
        },
        {
            path: '/help/team-admin',
            name: 'teamadmin',
            component: () =>
                import('../views/Help/TeamAdmin.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Help Center",
                    'link': '/help',
                },
                {
                    'name': "Team Admin",
                    'link': null,
                }
                ],
                can: 'dashboard',
            },
        },
        {
            path: '/help/event-manager',
            name: 'eventmanager',
            component: () =>
                import('../views/Help/EventManager.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Help Center",
                    'link': '/help',
                },
                {
                    'name': "Event Manager",
                    'link': null,
                }
                ],
                can: 'dashboard',
            },
        },
        {
            path: '/help/end-user',
            name: 'enduser',
            component: () =>
                import('../views/Help/EndUser.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Help Center",
                    'link': '/help',
                },
                {
                    'name': "End User",
                    'link': null,
                }
                ],
                can: 'dashboard',
            },
        },
        ]
    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [{
            path: '/login',
            name: 'login',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/Login.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/Register.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/reset-password/:token',
            name: 'resetpassword',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/ResetPassword.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/secondary/reset-password/:token',
            name: 'secondaryResetpassword',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/SecondaryResetPassword.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/manager/reset-password/:token',
            name: 'managerResetpassword',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/ManagerResetPassword.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/email-sent',
            name: 'RegistrationEmailSent',
            component: () =>
                import( /* webpackChunkName: "resetpassword" */ '../authpages/RegistrationEmailSent.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgotpassword',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/ForgotPassword.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/forgot-password-email-sent',
            name: 'forgotpasswordemailsent',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/ForgotPasswordEmailSent.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/password-reset-successfully',
            name: 'emailsentsuccess',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/ResetPasswordSuccessfully.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/verify-email/:userId',
            name: 'Emailverified',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/EmailVerified.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        {
            path: '/password-reset-token-expired',
            name: 'PasswordResetTokenExpired',
            component: () =>
                import( /* webpackChunkName: "login" */ '../authpages/PasswordResetTokenExpired.vue'),
            meta: {
                isAuthPage: true,
                requiresAuth: false
            }
        },
        ]
    },

    // {
    // 	name: 'rsvpinvitation',
    // 	path: '/rsvp/:rsvp_url_id',
    // 	component: () => import(/* webpackChunkName: "login" */ '../views/Rsvp/EventDetails.vue')
    // },
    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: () =>
            import( /* webpackChunkName: "login" */ '../authpages/PageNotFound.vue')
    },
    {
        path: '/invitelinkexpired',
        name: 'Invite Link Expired',
        component: () =>
            import('../components/InviteLinkExpired.vue'),
    },
    {
        name: 'SubscriptionEmpty',
        path: '/unsubscription',
        component: () =>
            import( /* webpackChunkName: "login" */ '../authpages/SubscriptionEmpty.vue')
    },
    {
        path: '/',
        component: MemberDashboardLayout,
        children: [{
            path: '/member/dashboard',
            name: 'member/dashboard',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Dashboard/MemberUser/MemberUserDashboard.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Dashboard",
                    'link': null,
                }],
                can: "dashboard"
            },
        },
        {
            path: '/member-profile',
            name: 'member-profile',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Member Profile",
                    'link': null,
                }],
                can: "profile"
            },
        },
        {
            path: '/profileregistration',
            name: 'ProfileRegistration',
            component: () =>
                import( /* webpackChunkName: "MemberDashboardLayout" */ '../views/ProfileRegistration/ProfileRegistration.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Profile Registration",
                    'link': null,
                }],
                can: "profileregistration"
            },
        },
        {
            path: '/member/profilepayment/:rowId',
            name: 'ProfilePayment',
            component: () =>
                import( /* webpackChunkName: "MemberDashboardLayout" */ '../views/ProfilePayment/ProfileRegistration.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Profile Payment",
                    'link': null,
                }],
            },
        },
        {
            path: '/member-history',
            name: 'member-history',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/MembershipHistoryView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership History",
                    'link': null,
                }],
                can: "membership_history"
            },
        },
        {
            path: '/member/partners',
            name: 'partners',
            component: () =>
                import( /* webpackChunkName: "Partners" */ '../views/Dashboard/MemberUser/Partners.vue'),
            meta: {
                breadcrumb: [{
                    'name': "partners",
                    'link': null,
                }],
                can: "partners"
            },
        },
        {
            path: '/member/preference',
            name: 'memberpreference',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Preference/MemberPreference.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Preference",
                    'link': null,
                }],
                can: "preference"
            },
        },
        {
            path: '/member/instalment',
            name: 'memberinstalment',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/InstalmentView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Installment History",
                    'link': null,
                }],
                can: "instalment"
            },
        },
        {
            path: '/event-history',
            name: 'event-history',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/EventsHistory/EventsHistory.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Events History",
                    'link': null,
                }],
                can: "event_history"
            },
        },
        {
            path: '/upcoming-events',
            name: 'upcoming-events',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/EventsHistory/UpcomingEvents.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Upcoming Events",
                    'link': null,
                }],
                can: "upcoming_events"
            },
        },
        {
            path: '/renew',
            name: 'RenewMemberWizard',
            component: () => import(/* webpackChunkName: "MemberDashboardLayout" */ '../views/Renewal/Individual/RenewalDataView'),
            meta: {
                breadcrumb: [{
                    'name': "Renew Membership",
                    'link': null,
                }
                ],
                can: "renew"
            },
        },
        ]
    },
    {
        path: '/',
        component: ClubDashboardLayout,
        children: [{
            path: '/club/dashboard',
            name: 'club-dashboard',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Dashboard/ClubUser/ClubUserDashboard'),
            meta: {
                breadcrumb: [{
                    'name': "Team Dashboard",
                    'link': null,
                }],
                can: "dashboard"
            },
        },
        {
            path: '/club/profile',
            name: 'club-profile',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ClubProfileView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Team Profile",
                    'link': null,
                }],
                can: "profile"
            },
        },
        {
            path: '/club/staff',
            name: 'club-staff',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/CoachRosterView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Team Official Roster",
                    'link': null,
                }],
                can: "staff"
            },
        },
        {
            path: '/club/preference',
            name: 'preference',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Preference/Preference.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Preference",
                    'link': null,
                }],
                can: "preference"
            },
        },
        {
            path: '/club/insurance',
            name: 'insurance',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../components/Insurance.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Insurance",
                    'link': null,
                }],
                can: "insurance"
            },
        },
        {
            path: '/club/roster',
            name: 'club-roster',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/MemberRosterView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Team Member Roster",
                    'link': null,
                }],
                can: "roster"
            },
        },
        {
            path: '/club/membership-history',
            name: 'club-membership-history',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/ClubMembershipHistoryView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Membership History",
                    'link': null,
                }],
                can: "membership_history"
            },
        },
        {
            path: '/club/player-transfer-request',
            name: 'club-player-transfer-request',
            component: () =>
                import( /* webpackChunkName: "DashboardLayout" */ '../views/Registration/PlayerTransferRequestView.vue'),
            meta: {
                breadcrumb: [{
                    'name': "Player Transfer Request List",
                    'link': null,
                }],
                can: "player_transfer_request"
            },
        },
        {
            path: '/club/partners',
            name: 'club-partners',
            component: () =>
                import( /* webpackChunkName: "ClubPartners" */ '../views/Dashboard/MemberUser/Partners.vue'),
            meta: {
                breadcrumb: [{
                    'name': "partners",
                    'link': null,
                }],
                can: "partners"
            },
        },
        {
            path: '/club/renew',
            name: 'RenewTeamWizard',
            component: () => import(/* webpackChunkName: "RenewTeamWizard" */ '../views/Renewal/Team/RenewalDataView'),
            meta: {
                breadcrumb: [{
                    'name': "Renew Membership",
                    'link': null,
                }],
                can: "renew"
            },
        },
        {
            path: '/club/upgrade',
            name: 'UpgradeTeamWizard',
            component: () => import(/* webpackChunkName: "RenewTeamWizard" */ '../views/Upgrade/Team/UpgradeDataView'),
            meta: {
                breadcrumb: [{
                    'name': "Upgrade Membership",
                    'link': null,
                }],
                can: "upgrade"
            },
        },
        ]
    },
]
let productSlug = process.env.VUE_APP_PRODUCT_SLUG;
const router = createRouter({
    history: createWebHistory(productSlug),
    routes
});

// async function club_domain() {
// 	var basePath =	process.env.VUE_APP_URL;
// 	await axios.get(basePath + "api/club-domain", {
// 		headers: {
// 			Accept: "application/json",
// 			"Content-Type": "application/json"
// 		}
// 	}).then((response) => {
// 		let data = response.data.data;
// 		console.log(data.status);
// 		if (data.status == "success") {
// 			let clientId = data.client_id;
// 			console.log('Client Id s' + clientId);
// 			localStorage.setItem("ClientId", clientId);
// 			axios.defaults.headers.common['ClientId'] = clientId;
// 		}
// 	})
// 	.catch((error) => {

// 	})
// 	.finally(() => {
// 		//this.$router.push('/login');
// 	});

// 	return '';
// }

// router.beforeEach((to, from, next) => {
//     var basePath = process.env.VUE_APP_URL;
// if (localStorage.getItem('ClientId') == null || localStorage.getItem('ClientId') == 0) {
//     axios
//         .get(basePath + "api/club-domain", {
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })
//         .then((response) => {
//             let data = response.data.data;
//             console.log(data.status);
//             if (data.status == "success") {
//                 let clientId = data.client_id;
//                 console.log('Client Id s' + clientId);
//                 localStorage.setItem("ClientId", clientId);
//                 axios.defaults.headers.common['ClientId'] = clientId;
//             }
//         })
//         .catch((error) => {

//         })
//         .finally(() => {
//             //this.$router.push('/login');
//         });
// }
// if (to.meta.requiresAuth) {
//     if (localStorage.getItem("userToken") == null) {
//         next({ path: "/login" });
//     } else {
//         next();
// axios
//   .get(process.env.VUE_APP_URL + "api/user_verify", {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("userToken"),
//     },
//   })
//   .then((response) => {
//     const resp = response.data;
//     if(resp){
//       next();
//     }else{
//       localStorage.removeItem("userToken");
//       next({ path: "/login" });
//     }
//   })
//   .catch((error) => {
//     localStorage.removeItem("userToken");
//     next({ path: "/login" });
//   });
// }
//}
// if (to.meta.isAuthPage && localStorage.getItem("userToken") != null) {
//     next({ path: "/dashboard" });
// } else {
//     next();
// }
// });

async function addFbMeta() {
    let client = window.location.hostname.split('.')[0];       
    if (client) {
        let path = '';
        try {
            path = window.origin + '/mmsapp/config/' + client + '_landing_fbmeta.js';
        }
        catch (error) {
            path = window.origin + '/mmsapp/config/landing_fbmeta.js';
        }

        try {
            // Use fetch to check if the script file exists
            const response = await fetch(path, { method: 'HEAD' });
            if (response.ok) {
                // If the file exists, add the script to the DOM
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = path;
                script.async = true;
                script.id = "fbmeta_script";
                // Append the script to the head
                document.head.appendChild(script);
                script.onload = () => {
                    console.log('Meta script loaded and executed.');
                };
                script.onerror = () => {
                    console.error('Failed to load the script.');
                };
            } else {
                console.error('meta script does not exist: ', path);
            }
        } catch (error) {
            console.error('Error fetching the meta script:', error);
        }        
    }
    console.log('added..')
}

function removeFbMeta() {
    const scriptElems = document.querySelectorAll('#fbmeta_script'); 
    scriptElems.forEach((scriptElem) => {
        scriptElem.remove(); 
    });
}

router.beforeEach(async (to, from, next) => {   

    if ((new URL(window.location)).searchParams.get('loginStatus') === 'logout') {
        localStorage.clear();
        const cookies = document.cookie.split(";");

        // Loop through each cookie and delete it
        cookies.forEach(cookie => {
            const cookieParts = cookie.split("=");
            const cookieName = cookieParts[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        });
        window.location.href = window.location.origin + "/mms/login";
    }

    if ((new URL(window.location)).searchParams.get('email') !== null) {
        const email = CryptoJS.AES.decrypt(decodeURIComponent((new URL(window.location)).searchParams.get('email')), 'isportz').toString(CryptoJS.enc.Utf8)

        const loginForm = {
            grant_type: 'password',
            client_id: 'mms',
            username: email,
            password: 'Test@123',
        };
        const response = await login(loginForm);
        let keycloak = decodeKeycloakToken(response.access_token);
        const roles = [];
        if (typeof keycloak.resource_access?.mms !== 'undefined' && ['admin', 'manager', 'member', 'team'].filter(x => keycloak.resource_access.mms.roles.includes(x)).length != 0) {
            localStorage.setItem("role", keycloak.resource_access.mms.roles);
        } else {
            console.log('User Role Not Found')
        }
        if ((new URL(window.location)).searchParams.get('fundraiser') !== null) {
            window.location.href = new URL(window.location.href).origin + "/fundraiser/campaigns";
        } else {
            window.location.href = window.location.href.split('?')[0];
        }
    }
    if (to.meta.requiresAuth) {
        if (localStorage.getItem("token") == null) {
            next({ path: "/login" });
        } else {
            next({ path: "/dashboard" });
        }
    } else if (to.meta.isAuthPage && localStorage.getItem("token") != null) {
        next({ path: "/dashboard" });
    } else if (to.meta.isAuthPage && localStorage.getItem("token") == null) {
        next();
    } else {
        if (localStorage.getItem("token") != null && to.name == "dashboard") {
            let role = getRoles("mms");
            if (role && role[0] == "member") {
                next({ path: "/member/dashboard" });
            } else if (role && role[0] == "team") {
                next({ path: "/club/dashboard" });
            }
        }
        next();
    }

    //added fb pixel meta in landing page.
    if (to.path === '/' || to.path === '') {
        addFbMeta()
    }
    else {
        removeFbMeta()
    }
});
export default router